import { color, space, typography } from 'styled-system';
import styled from 'styled-components';
import { Link as InertiaLink } from '@inertiajs/react';
import { motion } from 'framer-motion';

const Link = styled(motion(InertiaLink))`
    display: block;
    ${space}
    ${typography}
    ${color}
`;

Link.defaultProps = {
    fontSize: 'sm',
    fontWeight: 'bold',
};

export default Link;
